

import { CF2Component } from 'javascript/lander/runtime'

export default class ModalV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount(el) {
    const node = this
    this.onClose = this.onClose ?? function() { }

    this.close = this.close ?? function () {
      if (node.element) {
        $('body').removeClass('hide-page-scroll')
        node.element.style.display = 'none'
        node.onClose()
      };
    }

    this.setupModalClose = function () {
      $(node.element).on('click', function (e) {
        if (e.target !== e.currentTarget) return;
        if (window.getSelection().type === 'Range') return
        node.close()
      });
      $(node.element).on('click', '.elModalClose', function () {
        node.close()
      });
    }

    this.setupModalClose()
  }



}

window["ModalV1"] = ModalV1

